export const ADMIN = "ADMIN";
export const CHAMPIONSHIPS = "CHAMPIONSHIPS";
export const CHAMPIONSHIP_AGE_GROUPS = "CHAMPIONSHIP_AGE_GROUPS";
export const CHAMPIONSHIP_GENDERS = "CHAMPIONSHIP_GENDERS";
export const CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS =
  "CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS";
export const CHAMPIONSHIP_EVENT_REQUIREMENTS =
  "CHAMPIONSHIP_EVENT_REQUIREMENTS";
export const CHAMPIONSHIP_PARTICIPATION = "CHAMPIONSHIP_PARTICIPATION";
export const CHAMPIONSHIP_TIERS = "CHAMPIONSHIP_TIERS";
export const CHECK_IN = "CHECK_IN";
export const CHECKOUT = "CHECKOUT";
export const COMPETITIONS = "COMPETITIONS";
export const COMPETITION_EVENT = "COMPETITION_EVENT";
export const COMPETITION_EVENTS = "COMPETITION_EVENTS";
export const COMPETITION_EVENT_CODES = "COMPETITION_EVENT_CODES";
export const COMPETITORS = "COMPETITORS";
export const CONNECTION = "CONNECTION";
export const CURRENT_TOURNAMENT = "CURRENT_TOURNAMENT";
export const DEDUCTION = "DEDUCTION";
export const ERROR_MESSAGES = "ERROR_MESSAGES";
export const EVENTS = "EVENTS";
export const EVENT_CATEGORIES = "EVENT_CATEGORIES";
export const EVENT_FEEDBACK = "EVENT_FEEDBACK";
export const EVENT_PARTICIPATION = "EVENT_PARTICIPATION";
export const FEES = "FEES";
export const FLIGHT_REGISTRATIONS = "FLIGHT_REGISTRATIONS";
export const GENDERS = "GENDERS";
export const HOTELS = "HOTELS";
export const HOTEL_REGISTRATIONS = "HOTEL_REGISTRATIONS";
export const HOTEL_ROOM_TYPES = "HOTEL_ROOM_TYPES";
export const INCOMPLETE_NANDU_FORMS = "INCOMPLETE_NANDU_FORMS";
export const JUDGE = "JUDGE";
export const JUDGES = "JUDGES";
export const MOVEMENT = "MOVEMENT";
export const MASTER_SHEET = "MASTER_SHEET";
export const NANDU = "NANDU";
export const NANDU_FORM_DATA = "NANDU_FORM_DATA";
export const NANDU_RULESETS = "NANDU_RULESETS";
export const NANDU_SCORES = "NANDU_SCORES";
export const NANDU_TYPES = "NANDU_TYPES";
export const NO_RING = "NO_RING";
export const PARTICIPANT = "PARTICIPANT";
export const PARTICIPANTS = "PARTICIPANTS";
export const RESTRICTED_COMPETITION_USER = "RESTRICTED_COMPETITION_USER";
export const RING = "RING";
export const RING_BREAKS = "RING_BREAKS";
export const RINGS = "RINGS";
export const RULESET_DEDUCTIONS = "RULESET_DEDUCTIONS";
export const RULESET_MOVEMENTS = "RULESET_MOVEMENTS";
export const RULESET_NANDU = "RULESET_NANDU";
export const RULESET_NANDU_CONNECTION = "RULESET_NANDU_CONNECTION";
export const SCHEDULE = "SCHEDULE";
export const SUPERUSER = "SUPERUSER";
export const TEAMS = "TEAMS";
export const TEAM_PARTICIPANTS = "TEAM_PARTICIPANTS";
export const TEAM_MEDALS = "TEAM_MEDALS";
export const TOURNAMENT = "TOURNAMENT";
export const TOURNAMENTS = "TOURNAMENTS";
export const TOURNAMENT_TICKET_PURCHASES = "TOURNAMENT_TICKET_PURCHASES";
export const TRANSACTIONS = "TRANSACTIONS";
export const USER = "USER";
export const USERS = "USERS";
export const USER_TICKET_PURCHASES = "USER_TICKET_PURCHASES";
export const VIDEO_SUBMISSIONS = "VIDEO_SUBMISSIONS";
export const WAIVER_AGREEMENTS = "WAIVER_AGREEMENTS";
export const WEBSITE_MESSAGES = "WEBSITE_MESSAGES";
export const WUSHU_STYLES = "WUSHU_STYLES";
