import type { ConnectionsMap, NanduMovement } from "api/nanduForm";
import type {
  NanduScores,
  PartialNanduFormDataNandu,
  Section,
  SelectedNandu,
} from "redux/nanduForm";

interface CalculateScore {
  score?: number;
  currTotal: number;
  maxTotal: number;
}

function calculateScore({
  score,
  currTotal,
  maxTotal,
}: CalculateScore): number {
  if (!score) {
    return 0;
  }

  let retScore = score;

  if (currTotal + retScore > maxTotal) {
    retScore = maxTotal - +currTotal.toFixed(2);
  }

  retScore = +retScore;

  return +retScore.toFixed(2);
}

interface CalculateNanduScore {
  sections: Section[];
  connections: ConnectionsMap;
  removedPlusConnection?: SelectedNandu;
}
interface CalculateNanduScoreReturn {
  sections: Section[];
  nanduScores: NanduScores;
}
type CounterMap = Record<string, number>;
type JumpConnection = {
  code: "+";
  formValue?: number;
};

/* removedPlusConnection is only present when the last operation was removing a + connection */
export function calculateNanduScore({
  sections,
  connections,
  removedPlusConnection,
}: CalculateNanduScore): CalculateNanduScoreReturn {
  const comboList: CounterMap = {}; // keeps track of existing combos
  const singleMoveList: CounterMap = {}; // keeps track of existing standalone moves
  const nanduOccurences: CounterMap = {}; // keeps track of how many times a nandu has been used, regardless of combo or standalone

  // rule 1: you cannot have duplicate standalone nandu
  // rule 2: you cannot have duplicate nandu combos
  // rule 3: you can only have max 2 of the same nandu if they have different connections

  let jumpScore = 0;
  let connectionScore = 0;
  let errorMessage = "";
  let hasThrowCatch = false; // per 2019 rules, only one throw-catch is allowed per form

  function calculateConnectionScore(
    combo: string,
    jumpConnection: JumpConnection
  ) {
    const calculatedConnectionScore = calculateScore({
      score: connections?.[combo]?.score,
      currTotal: connectionScore,
      maxTotal: 0.6,
    });
    jumpConnection.formValue = calculatedConnectionScore;
    connectionScore += calculatedConnectionScore;
  }

  if (sections[0]) {
    for (let j = 0; j < sections[0].nandu.length; j++) {
      const jumpConnection: JumpConnection = {
        code: "+",
      };
      const currentMove: PartialNanduFormDataNandu | undefined =
        sections[0].nandu[j];

      if (!currentMove) {
        continue;
      }

      const currentCode = currentMove.code;
      const currentScore = currentMove.score;
      const currentNanduType = currentMove.nanduType;

      // have to check inside the inner loop to ensure nandus[j] is not an empty object
      if (currentCode) {
        const prevMove = j === 0 ? undefined : sections[0].nandu[j - 1];
        let prevComboMove = prevMove;
        let combo = "";
        let isPartOfCombo = false;
        let isUniqueCombo = false;
        let isThirdInstance =
          nanduOccurences[currentCode] && nanduOccurences[currentCode] >= 2;

        if (prevMove) {
          // if it's not the first move in the whole form
          // if the prevMove is a '+', we can assume there is another move behind prevMove
          if (prevMove.code === "+") {
            prevComboMove = sections[0].nandu[j - 2];
          }

          if (prevComboMove) {
            combo = `${prevComboMove.code} ${currentCode}`;
          }

          isPartOfCombo = Boolean(connections[combo]);
          isUniqueCombo = !comboList[combo];
        }

        currentMove.formValue = 0; // the calculated value of the move

        if (isPartOfCombo && isUniqueCombo && !isThirdInstance) {
          const removedPlusConnectionIndex =
            removedPlusConnection && removedPlusConnection.nanduIndex === j;

          if (removedPlusConnectionIndex) {
            if (currentNanduType === "CONNECTION") {
              sections[0].nandu.splice(j, 1); // remove unused connection
              j--;
            }
          } else {
            const isThrowCatch = currentCode === "8";
            if (!isThrowCatch || !hasThrowCatch) {
              if (isThrowCatch) {
                hasThrowCatch = true;
              }

              if (prevMove?.code !== "+") {
                // add jumpConnection between prev and current move and increment counter to the appropriate index
                sections[0].nandu.splice(j, 0, jumpConnection);
                j++;
              }

              // TODO: don't hardcode these strings
              if (currentNanduType !== "CONNECTION") {
                currentMove.formValue = calculateScore({
                  score: currentScore,
                  currTotal: jumpScore,
                  maxTotal: 1.4,
                });
                jumpScore += currentMove.formValue;

                // unset the single move count when we find out it's actually part of a combo
                if (prevComboMove?.code && singleMoveList[prevComboMove.code]) {
                  singleMoveList[prevComboMove.code]! -= 1;

                  if (!prevComboMove?.formValue) {
                    const prevCalculatedJumpScore = calculateScore({
                      score: prevComboMove.score,
                      currTotal: jumpScore,
                      maxTotal: 1.4,
                    });
                    prevComboMove.formValue = prevCalculatedJumpScore;
                    jumpScore += prevCalculatedJumpScore;
                  }
                }
              } else if (
                // You are only allowed to have at most 2 of the same nandu in a connection count towards the total score.
                // TODO: test this, does this ever get hit given the lines above?
                prevComboMove?.code &&
                !prevComboMove?.formValue &&
                singleMoveList[prevComboMove.code]! <= 2
              ) {
                const prevCalculatedJumpScore = calculateScore({
                  score: prevComboMove.score,
                  currTotal: jumpScore,
                  maxTotal: 1.4,
                });
                prevComboMove.formValue = prevCalculatedJumpScore;
                jumpScore += prevCalculatedJumpScore;
              }

              if (prevComboMove?.code) {
                nanduOccurences[prevComboMove.code] = nanduOccurences[
                  prevComboMove.code
                ]
                  ? nanduOccurences[prevComboMove.code]! + 1
                  : 1;
                singleMoveList[prevComboMove.code]! -= 1;
              }
              calculateConnectionScore(combo, jumpConnection);
            }

            const comboInstance = comboList[combo];

            comboList[combo] = comboInstance ? comboInstance + 1 : 1;
          }
        } else if (currentCode !== "+" && currentNanduType !== "CONNECTION") {
          const singleMoveInstance = singleMoveList[currentCode];
          // if current move is unique by itself and is not the third instance of the same move
          if (
            (!singleMoveInstance || singleMoveInstance < 1) &&
            !isThirdInstance
          ) {
            const calculatedJumpScore = calculateScore({
              score: currentScore,
              currTotal: jumpScore,
              maxTotal: 1.4,
            });

            currentMove.formValue = calculatedJumpScore;
            jumpScore += calculatedJumpScore;
            currentMove.hasMaxInstances = false;
            singleMoveList[currentCode] = singleMoveInstance
              ? singleMoveInstance + 1
              : 1;
          } else {
            singleMoveList[currentCode]!++;
            currentMove.hasMaxInstances = true;
            errorMessage = "NON_UNIQUE_JUMP";
          }
        } else if (currentCode === "+" || currentNanduType === "CONNECTION") {
          sections[0].nandu.splice(j, 1); // remove unused connections
          j--;
        }
      }
    }
  }

  if (jumpScore > 1.4) {
    jumpScore = 1.4;
  }

  if (connectionScore > 0.6) {
    connectionScore = 0.6;
  }

  jumpScore = +jumpScore.toFixed(2);
  connectionScore = +connectionScore.toFixed(2);

  const totalScoreValue = jumpScore + connectionScore;
  const totalScore =
    totalScoreValue === 2
      ? totalScoreValue.toFixed(1)
      : totalScoreValue.toFixed(2);
  const jumpScoreIsMaxed = jumpScore >= 1.4;
  const totalScoreIsMaxed = totalScoreValue >= 2;
  const connectionScoreIsMaxed = connectionScore >= 0.6;
  return {
    sections,
    nanduScores: {
      totalScore,
      totalScoreIsMaxed,
      jumpScore,
      jumpScoreIsMaxed,
      connectionScore,
      connectionScoreIsMaxed,
      errorMessage,
      isValidNanduForm:
        jumpScoreIsMaxed && connectionScoreIsMaxed && totalScoreIsMaxed,
    },
  };
}

interface HasDuplicates {
  movement: NanduMovement;
  sections: Section[];
}

export function hasDuplicates({ movement, sections }: HasDuplicates) {
  let bool = false;

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section) {
      for (let j = 0; j < section.movement.length; j++) {
        const sectionMovement = section.movement[j];
        if (
          sectionMovement?.code === movement.code &&
          sectionMovement?.id === movement.id
        ) {
          bool = true;
        }
      }
    }
  }

  return bool;
}

interface HasAllRequiredMovements {
  movements: NanduMovement[];
  sections: Section[];
}

export function hasAllRequiredMovements({
  movements,
  sections,
}: HasAllRequiredMovements): boolean {
  // Convert movements array to Map
  const moveMap = movements.reduce<Map<number, number>>((acc, movement) => {
    acc.set(movement.id, 0);

    return acc;
  }, new Map());

  let bool = true;

  // Update the Map based on sections
  for (const section of sections) {
    const sectionMovement = section?.movement;

    if (sectionMovement) {
      for (const movement of sectionMovement) {
        const id = movement?.id;

        if (id) {
          moveMap.set(id, (moveMap.get(id) || 0) + 1);
        }
      }
    }
  }

  // Check the Map for required movements
  for (const [key, value] of moveMap) {
    if (value < 1) {
      bool = false;
      break; // No need to continue if any movement is missing
    }
  }

  return bool;
}
