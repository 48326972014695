import handleError from "helpers/handleError";

export function loadState(name = "state") {
  try {
    const serializedState = localStorage.getItem(name);

    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    handleError(err);

    return undefined;
  }
}

export function saveState(state, name = "state") {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(name, serializedState);
  } catch (err) {
    handleError(err);
  }
}

export function loadReduxState() {
  const state = loadState();

  if (state) {
    delete state.toasts;
  }

  return state;
}
