import { AnyAction, combineReducers } from "@reduxjs/toolkit";

import { LOGOUT } from "./auth/actionTypes";
import { AuthReducer as auth } from "./auth";
import { CollapseGroupReducer as collapseGroup } from "./collapseGroup";
import { CompetitionDaysSelectReducer as competitionDaysSelect } from "./competitionDaysSelect";
import { CompetitionSelectReducer as competitionSelect } from "./competitionSelect";
import admin from "./admin/reducers";
import adminDashboard from "./adminDashboard";
import i18n from "./i18n";
import nanduForm from "./nanduForm";
import nanduJudging from "./nanduJudging";
import participantTabs from "./participantTabs";
import { RingSelectReducer as ringSelect } from "./ringSelect";
import { ToastReducer as toasts } from "./toast";

const appReducer = combineReducers({
  auth,
  admin,
  adminDashboard,
  collapseGroup,
  competitionDaysSelect,
  competitionSelect,
  i18n,
  nanduForm,
  nanduJudging,
  participantTabs,
  ringSelect,
  toasts,
});

export type RootState = ReturnType<typeof appReducer>;

function rootReducer(state: RootState | undefined, action: AnyAction) {
  /**
   * Reset the root redux state. If in the case that we need some piece of the state to persist, we should instead explicitly clear
   * individual reducers rather than clear all but keep some.
   * Reference: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
   */
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;
