import { ADMIN, SUPERUSER } from "../../helpers/constants";

const AuthSelectors = {
  getUser(store) {
    return store.auth.user;
  },

  getAuthenticationStatus(store) {
    return store.auth.isAuthenticated;
  },

  getAuthToken(store) {
    if (store.auth.user) {
      return store.auth.user.authToken;
    }
  },

  isAdmin(store) {
    const { user, isAuthenticated } = store.auth;
    if (user) {
      const { role } = user;

      return isAuthenticated && (role === ADMIN || role === SUPERUSER);
    }
  },

  isSuperUser(store) {
    const { user, isAuthenticated } = store.auth;
    if (user) {
      const { role } = user;

      return isAuthenticated && role === SUPERUSER;
    }
  },

  isJudge(store) {
    const { user, isAuthenticated } = store.auth;
    if (user) {
      const { judgeId } = user;

      return isAuthenticated && judgeId;
    }
  },
};

export { AuthSelectors };
